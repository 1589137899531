body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

#root h1 {
  font-family: Plain-Medium,Arial,sans-serif;
  font-weight: bold;
}

#root h2, #root h3 {
  font-family: Plain-Medium,Arial,sans-serif;
}

#root p {
  font-family: Plain-Light,Arial,sans-serif;
  margin-bottom: 0px;
}

#root a {
  font-family: Plain-Light,Arial,sans-serif;
  text-decoration: none;
}

#root .MuiOutlinedInput-input {
  padding: 9px 14px;
}

@font-face {
  font-family: Plain-Medium;
  src: url("/fonts/plain-medium.woff2") format("opentype");
}

@font-face {
  font-family: Plain-Light;
  src: url("/fonts/plain-light.woff2") format("opentype");
}
